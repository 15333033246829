import React, { useState, useEffect, useContext, Context } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import useOutsideClick from "hooks/useOutsideClick";
import { anyObject, SelectOption } from "interfaces";
import classes from "styles/components/Select.module.scss";
import { BlackCheck, SelectArrow } from "assets";

type Props = {
  options: SelectOption[];
  name: string;
  setValue: UseFormSetValue<FieldValues>;
  placeholder?: string;
  context?: Context<any>;
  searchable?: boolean;
  defaultValue?: string;
  dropUpward?: boolean;
  dropHeight?: number;
  allowCustom?: boolean;
};

const Select = ({
  options,
  name,
  setValue,
  placeholder,
  context,
  searchable,
  defaultValue,
  dropUpward,
  dropHeight,
  allowCustom,
}: Props) => {
  if (allowCustom) searchable = true;

  const { values, setValues } = context
    ? useContext(context)
    : ({} as anyObject);
  const [search, setSearch] = useState<string>(values?.[name] || "");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setOpen] = useState(false);

  const selectRef = useOutsideClick(() => {
    if (isOpen) setOpen(false);
  }, [isOpen]);

  useEffect(() => {
    if (!allowCustom) setSearch("");
  }, [isOpen]);

  useEffect(() => {
    if (defaultValue) setValues?.((p) => ({ ...p, [name]: defaultValue }));
  }, []);

  useEffect(() => {
    if (allowCustom) setValues((p) => ({ ...p, [name]: search }));
  }, [search]);

  useEffect(() => {
    if (!search) return setFilteredOptions(options);
    setFilteredOptions(
      options.filter(
        (option) =>
          option.value.toUpperCase().includes(search.toUpperCase()) ||
          option.label?.toUpperCase().includes(search.toUpperCase()) ||
          option.searchKeys?.find((key) =>
            key.toUpperCase().includes(search.toUpperCase())
          )
      )
    );
  }, [search, isOpen]);

  const currentOption = options.find((o) => o.value === values?.[name]);
  return !values ? (
    <></>
  ) : (
    <div ref={selectRef} className={classes.container}>
      <div
        className={[
          classes.selectSelected,
          isOpen ? classes.active : "",
          values && values[name] ? "" : classes.placeholder,
          allowCustom ? classes.allowCustom : "",
        ].join(" ")}
        onClick={searchable ? () => setOpen(true) : () => setOpen((p) => !p)}
      >
        {searchable && isOpen ? (
          <input
            autoFocus
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        ) : (
          <p>
            {currentOption
              ? currentOption.label || currentOption.value
              : values && values[name]
              ? values[name]
              : placeholder}
          </p>
        )}
        {!allowCustom && <SelectArrow />}
      </div>
      {(allowCustom ? isOpen && search : isOpen) && (
        <div
          className={[classes.dropDown, dropUpward ? classes.upward : ""].join(
            " "
          )}
        >
          <div
            style={{ maxHeight: dropHeight }}
            className={classes.selectItems}
          >
            {placeholder && !allowCustom && (
              <div className={classes.head}>
                {!currentOption && <BlackCheck />}
                <p>{placeholder}</p>
              </div>
            )}
            {(searchable
              ? allowCustom && search
                ? [
                    { value: search },
                    ...filteredOptions.filter((i) => i.value !== search),
                  ]
                : filteredOptions
              : options
            ).map((item) => (
              <div
                key={item.value}
                className={[
                  classes.item,
                  item.value === values?.[name] ? classes.selected : "",
                ].join(" ")}
                onClick={() => {
                  setValue(name, item.value);
                  setOpen(false);
                  setValues?.((p) => ({ ...p, [name]: item.value }));
                }}
              >
                {item.value === values?.[name] && <BlackCheck />}
                <p>{item.label || item.value}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
