import React, {
  Context,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import classes from "styles/components/FormInput.module.scss";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import countries from "data/countries";
import { CountrySelectArrow } from "assets";
import useOutsideClick from "hooks/useOutsideClick";
import { anyObject, Country } from "interfaces";
import useEventListener from "hooks/useEventListener";
import useLocalStorage from "hooks/useLocalStorage";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  register: UseFormRegister<FieldValues>;
  context?: Context<any>;
  setValue: UseFormSetValue<FieldValues>;
}

export default function PhoneInput({
  placeholder,
  name,
  register,
  context,
  setValue,
  ...props
}: Props) {
  const { values, setValues } = context
    ? useContext(context)
    : ({} as anyObject);
  const [country, setCountry] = useLocalStorage<Country>(
    "country",
    countries.find(({ code, phone_code }) =>
      values?.phone_code ? phone_code === values?.phone_code : code === "US"
    ) || {
      code: "",
      emoji: "",
      image: "",
      name: "",
      phone_code: "",
      unicode: "",
    }
  );
  const [listOpen, setListOpen] = useState(false);
  const listRef = useOutsideClick(() => {
    setListOpen(false);
  });
  const registered = register(name);
  const [num, setNum] = useState("");

  useEffect(() => {
    setValues((p) => ({ ...p, phone_code: country?.phone_code }));
  }, [country]);

  useEffect(() => {
    if (!values?.[name]) return;
    setValue(name, values[name]);
    const num = values[name].replace(/\D/g, "");
    setNum(num);
  }, [values?.[name]]);

  useEffect(() => {
    var [a1, a2, a3] = [3, 3, 4]; // format
    var [b1, b2, b3] = [
      num.length > a1 ? a1 : num.length,
      num.length > a1 + a2 ? a2 : num.length < a1 ? 0 : num.length - a1,
      num.length > a1 + a2 + a3
        ? a3
        : num.length < a1 + a2
        ? 0
        : num.length - (a1 + a2),
    ];
    var format =
      num.length > a1 + a2 ? "$1-$2-$3" : num.length > a1 ? "$1-$2" : "$1";

    const formatted = num
      .replace(/-/g, "")
      .replace(
        new RegExp(`^([0-9]{${b1}})([0-9]{${b2}})([0-9]{${b3}})$`, "g"),
        format
      );
    setValues?.((p) => ({ ...p, [name]: formatted }));

    if (num.length > 10 && num[0] === "0") {
      const [, ...rest] = num.split("");
      if (rest) setNum(rest.join(""));
    }
  }, [num]);

  const [pressed, setPressed] = useState("");
  const [filter, setFilter] = useState(pressed);
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    setFilter("");
  }, [listOpen]);

  useEffect(() => {
    if (timer <= 0) return setPressed("");
    setTimeout(() => {
      setTimer((p) => p - 0.2);
    }, 200);
  }, [timer]);

  useEffect(() => {
    if (pressed) setFilter(pressed);
  }, [pressed]);

  const listener = useCallback(
    ({ key }) => {
      if (!listOpen) return;
      if (key === "Escape") return setFilter("");
      if (key.length > 1) return;
      setPressed((p) => p + key);
      setTimer(2);
    },
    [listOpen]
  );

  useEventListener("keydown", listener);

  return (
    <>
      <div ref={listRef} className={classes.countrySelect}>
        <div onClick={() => setListOpen((p) => !p)} className={classes.current}>
          <div className={classes.image}>
            {country && <img src={country.image} />}
          </div>
          <div className={classes.arrow}>
            <CountrySelectArrow />
          </div>
        </div>
        {listOpen && (
          <div className={classes.countryList}>
            {countries
              .filter(
                (c) =>
                  c.phone_code &&
                  (c.name.toLowerCase().includes(filter.toLowerCase()) ||
                    c.phone_code.toLowerCase().includes(filter.toLowerCase()))
              )
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              .map((c) => (
                <div
                  key={c.code}
                  onClick={() => {
                    setCountry(c);
                    setListOpen(false);
                  }}
                  className={classes.country}
                >
                  <img src={c.image} alt="" />
                  <p>
                    ({c.phone_code}) {c.name}
                  </p>
                </div>
              ))}
          </div>
        )}
      </div>
      <span className={classes.phone_code}>({country?.phone_code})</span>
      <input
        {...props}
        {...registered}
        value={values?.[name]}
        onChange={(e) => {
          const { value } = e.target;
          if (value.length > 12) return;
          if (value.length && !/^\d+$/.test(value.replace(/-/g, ""))) return;
          registered.onChange(e);
          setValues?.((p) => ({ ...p, [name]: value }));
        }}
        placeholder={placeholder}
        type="text"
      />
    </>
  );
}
