import React, { Context, useContext, useEffect } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { anyObject, FormProp } from "interfaces";
import classes from "styles/components/FormInput.module.scss";
import Select from "./Select";
import PhoneInput from "./PhoneInput";

interface Props {
  entry: FormProp;
  register: UseFormRegister<FieldValues>;
  errors: anyObject;
  setValue: UseFormSetValue<FieldValues>;
  context?: Context<any>;
  dropHeight?: number;
}

export default function FormInput({
  entry,
  register,
  errors,
  setValue,
  context,
  dropHeight,
}: Props) {
  const { values, setValues } = context
    ? useContext(context)
    : ({} as anyObject);
  if (entry instanceof Array)
    return (
      <div className={classes.flex}>
        {entry.map((e) => (
          <FormInput
            key={e.name}
            context={context}
            setValue={setValue}
            errors={errors}
            register={register}
            entry={e}
            dropHeight={dropHeight}
          />
        ))}
      </div>
    );

  if (entry instanceof Function) {
    const Entry = entry;
    return <Entry />;
  }

  const {
    name,
    label,
    type = "text",
    placeholder,
    options = [],
    forceValue,
    defaultValue,
    optional,
    disallowNumbers,
  } = entry;
  const registered = register(name);

  useEffect(() => {
    if (forceValue) setValues((p) => ({ ...p, [name]: forceValue }));
  }, [forceValue]);

  return (
    <div className={classes.container}>
      <label>
        {label}
        <span>{!optional && "*"}</span>
      </label>
      <div
        className={[
          classes.inputContainer,
          classes[type],
          errors?.[name] ? classes.errored : "",
          forceValue ? classes.forced : "",
        ].join(" ")}
      >
        {type === "phone" ? (
          <PhoneInput
            context={context}
            register={register}
            name={name}
            placeholder={placeholder}
            setValue={setValue}
          />
        ) : type === "select" ? (
          <Select
            {...entry}
            defaultValue={defaultValue}
            context={context}
            setValue={setValue}
            options={options}
            dropHeight={dropHeight}
          />
        ) : type === "textarea" ? (
          <textarea
            {...registered}
            value={values?.[name] || ""}
            onChange={(e) => {
              registered.onChange(e);
              setValues?.((p) => ({ ...p, [name]: e.target.value }));
            }}
            placeholder={placeholder}
          />
        ) : (
          <input
            {...registered}
            value={forceValue || values?.[name] || ""}
            onChange={(e) => {
              if (disallowNumbers && /\d/.test(e.target.value)) return;
              if (type === "number" && !/^[0-9]*$/.test(e.target.value)) return;
              registered.onChange(e);
              if (forceValue) return;
              setValues?.((p) => ({ ...p, [name]: e.target.value }));
            }}
            placeholder={placeholder}
            type={type === "number" ? "text" : type}
          />
        )}
      </div>
      {errors?.[name] && (
        <div className={classes.error}>
          {/* <div className={classes.icon}>
            <ErrorMark />
          </div> */}
          <p>{errors[name].replace(name, label || name)}</p>
        </div>
      )}
    </div>
  );
}
