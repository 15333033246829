import { useUser } from "contexts/user";
import { anyObject, FormProp, HandleSubmit } from "interfaces";
import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm as useHookForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getInt } from "utils/format";
import { AnyObjectSchema } from "yup";

interface Props {
  defaultValues?: any;
  schema?: AnyObjectSchema;
  entries: FormProp[];
  onSubmit?: HandleSubmit;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  useLocal?: boolean;
  values: anyObject;
  redirectStep?: number;
  setRedirectStep?: Dispatch<SetStateAction<number>>;
}

export default function useForm({
  redirectStep,
  schema,
  entries,
  onSubmit = () => {},
  setLoading,
  values = {},
  setRedirectStep,
}: Props) {
  const { user } = useUser();
  const [errors, setErrors] = useState<anyObject | null>(null);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  // const resolver = useYupValidationResolver(schema) as Resolver;
  const formProps = useHookForm({
    // resolver: schema ? resolver : undefined,
    defaultValues: {
      ...values,
      email: user?.email ? user.email : values.email,
    },
  });
  const { watch } = formProps;
  const resetSubmitted = () => setSubmittedOnce(false);
  const {
    query: { step: stepStr },
  } = useRouter();

  const step = getInt(stepStr?.toString());

  useEffect(() => {
    setRedirectStep?.(NaN);
  }, [step]);

  useEffect(() => {
    if (
      typeof redirectStep !== "number" ||
      isNaN(redirectStep) ||
      step === redirectStep
    )
      return;
    handleSubmit(redirectStep);
    setRedirectStep?.(NaN);
  }, [redirectStep]);

  useEffect(() => {
    getErrors().then(setErrors);
  }, [values]);

  const getErrors = async () => {
    try {
      if (!schema) return null;
      await schema.validate(values, { abortEarly: false });
      return null;
    } catch (error) {
      return error?.inner?.reduce(
        (prev, curr) => ({ ...prev, [curr.path]: curr.message }),
        {}
      );
    }
  };

  const handleSubmit = async (redirectStep?: number) => {
    setSubmittedOnce(true);
    const errors = await getErrors();
    setErrors(errors);
    if (errors) return setRedirectStep?.(NaN);
    setLoading?.(true);
    try {
      const { error, success } = (await onSubmit(redirectStep)) || {};
      if (error) toast.error(error.toString());
      if (success) toast.success(success);
    } catch (error) {
    } finally {
      setLoading?.(false);
    }
  };

  return {
    formProps: {
      ...formProps,
      entries,
      onSubmit: handleSubmit,
      errors: submittedOnce ? errors : null,
    },
    watch,
    resetSubmitted,
  };
}
