import React, {
  Context,
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
} from "react";
import classes from "styles/components/Form.module.scss";
import { UseFormReturn } from "react-hook-form";
import { anyObject, FormProp, HandleSubmit } from "interfaces";
import FormInput from "./FormInput";

interface Props extends UseFormReturn<any, object> {
  entries: FormProp[];
  onSubmit: HandleSubmit;
  children?: ReactNode;
  className?: string;
  context?: Context<{
    values: anyObject;
    setValues: Dispatch<SetStateAction<anyObject>>;
  }>;
  errors?: anyObject;
  dropHeight?: number;
  style?: CSSProperties;
}

export default function Form({
  className,
  entries,
  children,
  onSubmit: handleSubmit,
  register,
  handleSubmit: submitForm,
  errors,
  setValue,
  context,
  dropHeight,
  style,
}: Props) {
  return (
    <form
      style={style}
      className={[className].join(" ")}
      onSubmit={submitForm(handleSubmit)}
    >
      <div className={classes.container}>
        {entries.map((entry, idx) => (
          <FormInput
            key={entry instanceof Array ? idx : entry.name}
            context={context}
            setValue={setValue}
            errors={errors}
            register={register}
            entry={entry}
            dropHeight={dropHeight}
          />
        ))}
      </div>
      {children}
    </form>
  );
}
