import { anyObject } from "interfaces";
import Head from "next/head";
import { FC, Fragment } from "react";

interface Tags {
  title?: string;
  description?: string;
  [key: string]: string;
}

interface Props {
  tags: Tags;
  noHead?: boolean;
}

const Meta: FC<Props> = ({ tags, noHead }) => {
  const Wrapper: FC = noHead ? Fragment : Head;
  return (
    <Wrapper>
      <title>{tags.title}</title>
      {Object.entries(tags).map(([key, value]) => (
        <meta key={key} name={key} content={value} />
      ))}
    </Wrapper>
  );
};

export default Meta;
